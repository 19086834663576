#work--wrapper--outer {
  background: $dark-bg;
  padding-top: $header-height;
}

#work--wrapper {
  font-size: 14px;
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  color: #fff;
  padding-top: $project-gap-inset;
  padding-bottom: $project-gap;
}

// option 1
// #work--overlays {
//   // position: fixed;
//   position: absolute;
//   background: rgba(0, 0, 8, 0.3);
//   font-size: 36px;
//   line-height: 42px;
//   width: 400px;
//   padding: $header-height 0 0 0;
//   box-sizing: border-box;
//   top: 0;
//   bottom: 0;
//   // z-index:20;
// }

// .work--overlay {
//   color: #aab4c5;
//   position: absolute;
//   // top: 50%;
//   top: 0;
//   left: 50px;
//   font-weight: 600;
//   display: none;
//   -webkit-backface-visibility: hidden;
//   transform: translateZ(0);
//   -webkit-transform: translateZ(0);
//   // transform: translateY(-50%);
//   // &.active {
//   //   display: block;
//   // }
// }


// option 2
#work--overlays {
  @include no_select;
  pointer-events: none;
  position: fixed;
  // position: absolute;
  background: transparent;
  width: 400px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  // transform: translateZ(0);
}

#work--overlay--shadow {
  @include gradient_3(90deg, rgba(0,0,8,1), 0%, rgba(0,0,8,1), 25%, rgba(0,0,8,0), 100%);
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  pointer-events: none;
}

.work--overlay {
  color: #aab4c5;
  position: absolute;
  top: 50%;
  left: 50px;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  display: none;
  transform: translateY(-50%) translateZ(0);
  margin-top: calc($header-height / 2);
}

.work--overlay--highlight {
  color: #fff;
}

.work--section {
  @include no_select;
  // margin: 0 65px 0 232px;
  margin: 0;
  .work--project {
    @include smoothly(opacity, 0.25s);
    opacity: 0.25;
    &:hover {
      opacity: 1;
      .work--project--text {
        opacity: 1;
      }
    }
  }
  &.active {
    .work--project {
      opacity: 1;
      .work--project--text {
        opacity: 1;
      }
    }
  }
}

.work--project {
  @include airtight;
  position: relative;
  width: 886px;
  margin: 0 24px 0 auto;
  padding: 0;
  a, a:hover, a:focus, a:active, a:visited {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
  a:hover {
    .work--project--title {
      color: #a8b5ca;
    }
    .work--project--desc {
      color: #ffffff;
    }
  }
}

@include scale_project_images($project-unit-wide);

.work--project--image,
.work--project--text {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.work--project--image {
  @include smoothly(transform, 0.25s);
  margin: $project-gap-inset 0;
  overflow: hidden;
  text-align: right;
  transform-origin: right center;
  img {
    position: relative;
    left: unset;
    right: 0;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
  }
}

.work--project--text {
  @include smoothly(opacity, 0.25s);
  width: auto;
  height: auto;
  top: 50%;
  padding-left: $project-text-left-gap;
  opacity: 0.5;
  transform: translateY(-50%);
  transform-origin: left center;
}

.work--project--title {
  @include smoothly(color, 0.25s);
  // color: #8790a3;
  color: #a8b5ca;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}

.work--project--desc {
  @include smoothly(color, 0.25s);
  // color: #a8b5ca;
  color: #a8b5ca;
  font-size: 22px;
  line-height: 28px;
}

.work--project--shadow {
  @include smoothly(opacity, 0.25s);
  @include gradient_3(180deg, rgba(0,0,8,0), 0%, rgba(0,0,8,0.25), 50%, rgba(0,0,8,0.75), 100%);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.br--work {
  display: inline;
}

.br--work--medium {
  display: none;
}

.br--work--compact {
  display: none;
}


// archive
#work--archive--wrapper {
  @include airtight;
  @include no_select;
  width: $archive;
  margin: 0 auto;
  padding: 64px 0;
}

#work--archive--header {
  font-weight: 700;
  font-size: 30px;
  height: 120px;
  color: #ffffff;
}

.work--archive--year-header {
  display: none;
}

.work--archive--project--year,
.work--archive--project--client,
.work--archive--project--name,
.work--archive--project--links {
  display: inline-block;
  vertical-align: top;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #515c6b;
}

.work--archive--project--year,
.work--archive--project--client,
.work--archive--project--name {
  margin-right: $archive-gutter;
}

.work--archive--project--year {
  width: $archive-year; // 70px
}

.work--archive--project--name {
  width: $archive-name; // 300px
}

.work--archive--project--client {
  width: $archive-client; // 280px
}

.work--archive--project--links {
  width: $archive-links; // 240px
}

.work--archive--project--year,
.work--archive--project--name {
  font-weight: 700;
}

.work--archive--project--client {
  .work--archive--project--client--fathom {
    color: #515c6b;
  }
}

.work--archive--project--links {
  position: relative;
  overflow-wrap: break-word;
  a, a:focus, a:active, a:visited {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .icon {
    display: inline;
    position: absolute;
    width: auto;
    height: auto;
    right: 100%;
    padding-right: 10px;
    color: inherit;
  }
}

.work--archive--project--links--external {
  @include smoothly(color, 0.25s);
    color: #d34867;
  .icon {
    color: #d34867;
    font-size: 14px;
    margin-top: 4px;
  }
  &:hover {
    color: #ffffff;
    .icon {
      color: #d34867;
    }
  }
}

.work--archive--project--links--case-study,
.work--archive--project--links--project-page {
  @include smoothly(color, 0.25s);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  font-size: 14px;
  line-height: 34px;
  margin-bottom: 8px;
  color: #aab4c5;
  .icon {
    color: #aab4c5;
    font-size: 12px;
  }
  &:hover {
    color: #ffffff;
    .icon {
      color: #aab4c5;
    }
  }
}

.work--archive--project {
  margin-bottom: $archive-gap;
  &.has-link {
    .work--archive--project--client,
    .work--archive--project--year {
      color: #aab4c5;
    }
    .work--archive--project--name,
    .work--archive--project--client,
    .work--archive--project--links {
      color: #ffffff;
    }
  }
}

// end archive

@media(max-width: $w-wide){ // 1200px
  .work--overlay {
    font-size: 36px;
    line-height: 44px;
  }

  #work--archive--wrapper {
    zoom: 0.85;
  }
}

@media(max-width: 1024px){

  #work--archive--wrapper {
    width: $archive-two-column;
    margin: 0 auto;
  }

  #work--archive--header {
    font-size: 36px;
  }

  .work--archive--year-header {
    display: block;
    font-size: 32px;
    color: #aab4c5;
    margin-top: 96px;
    margin-bottom: 64px;
  }

  #work--archive--header + .work--archive--year-header {
    margin-top: 0;
  }

  .work--archive--project {
    min-height: 96px;
  }

  .work--archive--project--year {
    display: none;
  }

  .work--archive--project--name,
  .work--archive--project--client,
  .work--archive--project--links {
    display: inline-block;
  }

  .work--archive--project--links {
    width: $archive-two-column-links;
    margin-left: $archive-link-icons;
    margin-top: 4px;
    margin-bottom: 6px;
  }

  .work--archive--project--name,
  .work--archive--project--client {
    float: left;
    width: $archive-two-column-project;
    margin-left: 0;
    margin-right: $archive-two-column-gutter;
  }

  .work--archive--project--name {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 8px;
  }

  .work--archive--project--client {
    margin-bottom: 18px;
  }

}

@media(max-width: $w-target){ // 1000px

  @include scale_project_images($project-unit-target);

  #work--wrapper {
    padding-top: $header-height + $project-gap-inset;
  }

  .work--section {
    margin: 0;
  }

  .work--project {
    width: $project-target;
    margin: 0 $work-side 0 auto;
  }

  .work--project--text {
    width: $project-text-target;
  }

  #work--overlays {
    top: $header-height;
    left: 0;
    right: 0;
    width: auto;
    bottom: auto;
    height: 65px;
  }

  .work--overlay {
    transform: none;
    margin: 0;
    top: $header-link-pad;
    left: $header-link-pad;
    font-size: 28px;
    line-height: 34px;
    .br--work {
      display: none;
    }
  }

  #work--overlay--shadow {
    @include gradient_4(180deg, rgba(0,0,8,1), 0%, rgba(0,0,8,0.8), 40%, rgba(0,0,8,0.05), 90%, rgba(0,0,8,0), 100%);
    width: 100%;
    top: $header-height;
    height: $header-height * 3;
  }

}

@media(max-width: $w-medium){ // 870px

  .br--work {
    display: none;
  }

  .br--work--medium {
    display: inline;
  }

  @include scale_project_images($project-unit-medium);

  .work--project {
    width: $project-medium;
    margin: 0 $work-side 0 auto;
  }

  .work--project--text {
    width: $project-text-medium;
  }

  .work--project--title {
    color: #a8b5ca;
  }

  .work--project--desc {
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
  }

  .work--overlay {
    font-size: 24px;
    line-height: 34px;
  }

}

@media(max-width: $w-compact) { // 700px

  .br--work--medium {
    display: none;
  }

  .br--work--compact {
    display: inline;
  }

  #work--wrapper {
    padding-top: $header-height + 40px;
  }

  .work--section {
    margin: 0 $work-side;
  }

  .work--project {
    width: auto;
    margin: $project-gap-thin 0;
    &.work--project--scale-5,
    &.work--project--scale-4,
    &.work--project--scale-3,
    &.work--project--scale-2,
    &.work--project--scale-1 {
      height: auto;
      .work--project--image {
        position: relative;
        margin: 0;
        height: auto;
        img {
          width: 100%;
          height: auto;
          top: 0;
          left: 0;
          transform: none;
        }
      }
    }
  }
  .work--project--shadow {
    display: block;
  }
  .work--project--image,
  .work--project--text {
    display: block;
    width: 100%;
    max-width: unset;
    top: unset;
    left: unset;
    transform: none;
  }
  .work--project--text {
    position: relative;
    left: 0;
    top: 100%;
    margin-top: -14px;
    bottom: unset;
    padding: 0;
    opacity: 0;
  }
  .work--project--title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .work--project--desc {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 16px;
  }
  .br--work {
    display: none;
  }
  .work--section {
    &.active {
      .work--project {
        opacity: 1;
        .work--project--text {
          opacity: 1;
        }
        &:hover {
          opacity: 1;
          .work--project--text {
            opacity: 1;
          }
        }
        .work--project--shadow {
          opacity: 1;
        }
      }
    }
    .work--project:hover {
      opacity: 0.25;
      .work--project--text {
        opacity: 0;
      }
    }
  }

  #work--archive--wrapper {
    width: 400px;
  }

  .work--archive--year-header {
    font-size: 26px;
    margin-top: 96px;
    margin-bottom: 48px;
  }

  .work--archive--project {
    min-height: unset;
  }

  .work--archive--project--name,
  .work--archive--project--client,
  .work--archive--project--links {
    display: block;
    width: auto;
    margin-top: 0;
    margin-right: 0;
  }

  .work--archive--project--name,
  .work--archive--project--client {
    float: none;
  }

  .work--archive--project--links--case-study,
  .work--archive--project--links--project-page {
    margin-bottom: 8px;
  }

}

@media(max-width: $w-narrow) { // 400px

  .br--work--compact {
    display: none;
  }

  .work--overlay {
    max-width: unset;
    font-size: 24px;
    line-height: 34px;
    right: 30px;
  }

  .work--project--text {
    position: relative;
    left: 0;
    top: 100%;
    margin-top: -14px;
    bottom: unset;
    padding: 0;
    opacity: 0;
  }

  .work--project--title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .work--project--desc {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }

  #work--archive--wrapper {
    width: 330px;
  }

}
