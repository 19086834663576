// top sizes

@mixin heroImage() {
  // background: url('../css/img/myriscope-cover.jpg') no-repeat;
  background: url('../css/img/multi-map-cover.jpg') no-repeat;
}

@mixin heroImageMobile() {
  // background: url('../css/img/myriscope-cover.jpg') no-repeat;
  background: url('../css/img/multi-map-cover-mobile.jpg') no-repeat $dark-bg;
}

header.home {
  // background: none;
  #header--home-link {
    opacity: 0;
    pointer-events: none;
  }
  #header-bg {
    opacity: 0;
    pointer-events: none;
  }
}

#home--wrapper {
  width: 100%;
  background: #000007;
}

#home--cover {
  margin: 0 auto;
  // width: 1024px;
  height: 100vh;//700px;
  position: relative;
  background: $dark-bg;
  // background: url('../css/img/myriscope-cover.jpg') no-repeat;
  // background-size: cover;
}

#home--cover-bg-blur {
  position: absolute;
  z-index: -1;
  left: -50px;
  top: -50px;
  right: -50px;
  bottom: -50px;
  @include heroImage();
  background-size: 120%;
  filter: blur(10px);
}

#home--cover-bg-top {
  position: absolute;
  // left: 50%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // width: 1024px;
  // margin-left: -512px;
  @include heroImage();
  background-size: cover;
}

#home--brand {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 65px;
  width: auto;
  height: auto;
  transform: translateY(-50%);
}

#home--logo {
  font-family: 'Fathom Icons';
  position: relative;
  color: #cacecf;
  font-size: 20px;//16px;
}

#home--statement {
  font-size: 36px;//30px;
  line-height: 42px;//36px;
  font-weight: 600;
  color: #fff;
  width: 340px;//300px;
  margin-top: 32px;
}

#home--cover--caption {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-100%);
  margin-bottom: 30px;
  width: 150px;
  margin-left: -40px;
  &.hidden {
    display: none;
  }
}

#home--cover--caption-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #f69b64;
}

#home--cover--caption-desc {
  color: #cb6645;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

#home--features {
  background: $dark-bg;
  opacity: 0.2;
}

#home--features--inner {
  margin: 0 auto;
  // height: 800px;
  // max-width: 1024px;
}

.home--features--link {
  text-decoration: none;
}

.home--feature {
  @include no_select();
  position: relative;
  width: 1024px;
  margin: 0 auto;
  height: 400px;
  &:hover {
    .home--features--bg {
      opacity: 1;
    }
  }
}

.home--features--desc {
  font-size: 22px;
  line-height: 28px;
  color: #aab4cc;
  font-weight: 400;
}

.home--features--desc--highlight {
  color: #fff;
}

.home--features--action {
// @include letter_spacing(50);
  position: relative;
  color: $project-red;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-top: 24px;
}

.home--features--caret {
  @include no_select();
  font-family: 'Fathom Icons';
  font-size: 10px;
  position: absolute;
  top: -2px;
  left: -16px;
  margin: 0;
  padding: 0;
}

.home--features--action--sub {
  color: #5b6887;
  display: inline-block;
}

#home--features--covert {
  height: 360px;
}

.home--features--bg {
  @include smoothlyDelay(opacity, 0.2s, 0.2s);
  opacity: 0.85;
}

#home--features--covert--bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #011119;
  background-image: url('../css/img/feature-covert.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

#home--features--covert--title {
  margin-bottom: 20px;
  width: 200px;
  height: 20px;
  background-image: url('../css/img/fathom-plus-stanford.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

#home--features--covert--text {
  margin: 0px 48px;
  padding: 55px 0;
  width: 500px;
  z-index: 22;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#home--features--mayo {
  height: 360px;
  background: #191619;
}

#home--features--mayo--bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 360px;
  height: 100%;
  background-color: #102022;
  background-image: url('../css/img/feature-mayo.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

#home--features--mayo--text {
  float: right;
  width: 380px;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 0 0 60px;
  float: left;
  margin-left: 360px;
}

#home--features--mayo--secondary-text {
  float: right;
  width: 280px;
  box-sizing: border-box;
  color: #aab4cc;
  font-size: 16px;
  line-height: 22px;
  padding: 84px 0 0;

  p {
    margin: 0 0 10px 0;
    padding: 0;
  }
}

@media (max-width: 999px){
  #home--cover--caption {
    display: none;
  }
}

@media (max-width: 768px){

  .br {
    display: none;
  }

  #home--cover {height: 100vh !important;}

  #home--cover-bg-top {
    // background: url('../css/img/myriscope-cover-mobile.jpg') no-repeat $dark-bg;
    @include heroImageMobile();
    background-size: cover;//100%;
    height: auto !important;
  }

  #home--logo {
    display: none;
  }

  header.home {
    #header--home-link {
      opacity: 1;
      pointer-events: all;
    }
  }

  #home--brand {
    top: 200px;
    left: 50px;
  }

  #home--statement {
    // top: 25%;
    // position: static;
    // margin-top: 150px;
    // margin-left: 80px;
    // margin-right: 80px;
    font-size: 40px;
    margin: 0;
    transform: none;
    line-height: 52px;
    width: 370px;
    // width: auto;
  }

  .home--feature {
    width: 100%;
  }

  #home--features--covert {
    min-height: 360px;
    height: auto;
  }

  #home--features--covert--bg {
    background-size: cover;
    background-position-x: right;
    opacity: 0.3;
  }

  #home--features--covert--text {
    margin: 0 40px;
    // position: static;
    width: auto;
  }

  #home--features--mayo {
    background: #102022;
  }

  #home--features--mayo--bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-position: center;
  }

  #home--features--mayo--text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    float: none;
    height: auto;
    margin: 0;
    padding: 65px 35px;
  }

  #home--features--mayo--secondary-text {
    display: none;
  }
}

@media (max-width: $w-narrow){
  #home--brand {
    zoom: 1;
    //top: 92px;
    left: 50px;
  }

  #home--statement {
    font-size: 24px;//18px;
    line-height: 36px;//26px;
  }
}
