
#about {
  height: auto;
  color: #590a05;

  &.team {
    color: #590a05;
    #about--team {
      display: block;
    }
    #about--menu {
      color: #b2403d;
    }
    #about--bg--fill {
      background: #dc825e;
      &:after {
        opacity: 0;
      }
    }
    #about--bg--image {
      opacity: 0;
    }
  }

  &.talks {
    color: #ffffff;
    #about--talks {
      @include no_select;
      display: block;
      padding-top: 12px;
    }
    #about--bg--fill {
      background: #141f2b;
      &:after {
        opacity: 1;
      }
    }
    #about--bg--image {
      opacity: 0;
    }
    #about--menu {
      color: #444e5e;
    }
  }

  &.press {
    color: #ffffff;
    #about--press {
      display: block;
    }
    #about--bg--fill {
      background: #2f1111;
      &:after {
        opacity: 0;
      }
    }
    #about--bg--image {
      opacity: 1;
      background-image: url('../css/img/press.png');
    }
    #about--menu {
      color: #88414e;
    }
  }

  &.clients {
    color: #ffffff;
    #about--clients {
      @include no_select;
      display: block;
      padding-top: 12px;
      columns: 2;
    }
    #about--bg--fill {
      background: #141f2b;
      &:after {
        opacity: 1;
      }
    }
    #about--bg--image {
      opacity: 0;
    }
    #about--menu {
      color: #444e5e;
    }
  }

  &.team #about--menu--team,
  &.talks #about--menu--talks,
  &.press #about--menu--press,
  &.clients #about--menu--clients {
    color: #ffffff;
  }

  &.team,
  &.talks,
  &.press,
  &.clients {
    #about--menu--process {
      color: inherit;
      &:hover {
        color: #ffffff;
      }
    }
    #about--process {
      display: none;
    }
  }

}

#about--wrapper {
  max-width: 1200px;
  width: auto;
  height: auto;
  margin: 0 auto;
  padding-top: $about-overhead;
  padding-bottom: $section-gap;
}

#about--bg--fill,
#about--bg--image {
  display: block;
  position: fixed;
  transform: translateZ(0);
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#about--bg--fill {
  @include smoothly(background-color, 0.25s);
  background-color: #de7737;
  z-index: 0;
  &:after {
    @include smoothly(opacity, 0.25s);
    @include gradient_2(180deg, #141f2b, 0%, $dark-bg, 100%);
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

#about--bg--image {
  @include smoothly(opacity, 0.25s);
  z-index: 5;
  opacity: 1;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../css/img/process.jpg');
}

#about--menu {
  @include no_select;
  position: fixed;
  z-index: 50;
  top: $about-overhead;
  left: 0;
  width: $about-menu;
  height: auto;
  margin: 0;
  padding: 0 0 0 40px;
  color: #b2403d;
  li {
    display: block;
    font-weight: 600;
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    list-style: none;
    margin: 0;
    cursor: pointer;
    @include smoothly(color, 0.15s);
    a, a:active, a:hover, a:visited {
      display: block;
      width: 100%;
      height: 100%;
      color: inherit;
      text-decoration: none;
    }
    &:hover {
      color: #ffffff;
    }
  }
}

.about--section {
  display: none;
  position: relative;
  z-index: 10;
  margin: 0 0 0 80px + $about-menu;
}

#about--process {
  display: block;
  padding-top: 4px;
  margin-right: 64px;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  p {
    max-width: 800px;
    & + p {
      margin-top: 16px;
    }
  }
}

#about--menu--process {
  color: #ffffff;
}

#about--team {
  padding-top: 12px;
}

// Team

.team-member {
  display: block;
  margin-bottom: 72px;
}

.team-member--pic,
.team-member--text {
  display: inline-block;
  vertical-align: top;
}

.team-member--pic {
  width: 160px;
  height: 248px;
  margin-top: 4px;
  overflow: hidden;
  @include no_select;
  img {
    width: auto;
    height: 100%;
  }
}

.team-member--text {
  max-width: 616px;
  margin-left: 48px;
}

.team-member--name {
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 24px;
  color: #ffffff;
}

.team-member--bio-column {
  display: inline-block;
  vertical-align: top;
  max-width: 280px;
  p {
    font-size: 14px;
    line-height: 20px;
  }
  p + p {
    margin-top: $bio-paragraph-gap;
  }
  &.team-member--bio-column-second {
    margin-left: 32px;
  }
  .team-member--bio--pronouns {
    font-weight: 600;
    margin-top: 24px;
  }
}

.italicize {
  font-style: italic;
}

.talk {
  & + .talk {
    margin-top: $section-gap;
  }
}

#about--press {
  margin: 0 0 0 $about-menu + 32px;
  padding-top: 14px;
  @include no_select;
}

.press-item {
  display: block;
  max-width: 640px;
  margin: 0 auto;
  cursor: default;
  & + .press-item {
    margin-top: 48px;
  }
  a, a:active, a:hover, a:visited {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    &:hover {
      .press-item--source {
        color: #ffffff;
        .icon {
          opacity: 1;
        }
      }
    }
  }
}

.press-item--secondary {
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  color: #aab4c5;
}

.press-item--title,
.press-item--source {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  line-height: 26px;
}

.press-item--title {
  width: 480px;
  font-weight: 700;
  color: #ffffff;
}

.press-item--source {
  @include smoothly(color, 0.3s);
  position: relative;
  margin-left: 48px;
  width: auto;
  max-width: 112px;
  font-weight: 400;
  color: #d34867;
  .icon {
    @include smoothly(opacity, 0.3s);
    opacity: 0.5;
    color: inherit;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 100%;
    margin-top: -1px;
    margin-right: 12px;
  }
}

.talk-cover {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.talk-item--embed {
  position: relative;
  width: 100%;
  height: 100%;
}

.talk-item {
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin: 0 12px 48px;
  width: 470px;
  height: 270px;
  text-align: center;
}


.talk--image {
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  img {
    width: 100%;
  }
}

.talk--cover {
  @include gradient_3(180deg, rgba(0,0,8,0), 0%, rgba(0,0,8,0), 50%, rgba(0,0,8,1), 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.talk--bottom {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  text-align: left;
}

.talk--button {
  display: block;
  position: absolute;
  vertical-align: top;
  height: $player-height;
  width: $player-button;
  margin-right: $player-button-after;
  padding: 0;
  background: none;
  line-height: $player-height;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  .icon {
    @include smoothly(font-size, 0.25s);
    display: inline-block;
    margin-left: -3px;
    font-size: $player-button;
  }
  .orb {
    @include smoothly(all, 0.5s);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-radius: calc($player-button / 2);
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  &.on {
    cursor: default;
    .icon {
      font-size: 0;
    }
    .orb {
      width: $player-button;
      height: $player-button;
      background-color: #45566e;
    }
  }
}

.talk--text {
  display: block;
  position: relative;
  margin: 0 $player-side;
  padding-top: $player-title-above;
  text-align: left;
}

.talk--title,
.talk--event {
  display: block;
}

.talk--title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}
.talk--event {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  color: #444e5e;
  margin-top: 12px;
}

// Clients

.client {
  font-size: 20px;
  // line-height: 36px;
  margin-bottom: 14px;
}

@media (max-width: 1100px) {
  .team-member--text {
    max-width: 544px;
  }
  .team-member--bio-column {
    display: inline-block;
    max-width: 256px;
  }

  #about {
    &.clients {
      #about--clients {
        columns: 1;
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .team-member--bio-column {
    display: block;
    max-width: 360px;
    &.team-member--bio-column-second {
      margin-top: $bio-paragraph-gap;
      margin-left: 0;
    }
  }
  .press-item {
    max-width: 560px;
  }
  .press-item--title {
    max-width: 360px;
  }
}

@media (max-width: 800px) {
  .about--section {
    margin-left: 60px + $about-menu;
  }
  #about--press {
    margin: 0 48px 0 $about-menu + 48px;
  }
  .press-item {
    transform: none;
    margin: 0 auto;
    padding-left: 12px;
    max-width: 360px;
  }
  .press-item--title,
  .press-item--source {
    display: block;
    max-width: unset;
    width: auto;
  }
  .press-item--source {
    margin: 16px 0 0;
  }
}

@media (max-width: 768px) {
  #about--process {
    padding-top: 8px;
    font-size: 18px;
    line-height: 30px;
  }
  .team-member {
    margin-right: 48px;
  }
  .team-member--pic,
  .team-member--text {
    display: block;
  }
  .team-member--text {
    margin-top: 32px;
    margin-left: 0;
  }
  .team-member--name {
    margin-bottom: 16px;
  }
  .team-member--bio-column {
    display: inline-block;
    max-width: 45%;
    &.team-member--bio-column-second {
      margin-top: 0;
      margin-left: 32px;
    }
  }
}

@media (max-width: 699px) {
  #about--process {
    padding-top: 11px;
    font-size: 16px;
    line-height: 26px;
    margin-right: 32px;
  }
  .about--section {
    margin-left: 60px + $about-menu;
  }
  .team-member--bio-column {
    display: block;
    max-width: 380px;
    &.team-member--bio-column-second {
      margin-top: $bio-paragraph-gap;
      margin-left: 0;
    }
  }
}

@media (max-width: 500px) {
  .about--section {
    margin-left: 40px + $about-menu;
  }
}

@media(max-width: $w-narrow){
  #about--wrapper {
    padding-top: 100px;
  }

  .about--section {
    margin-left: 32px;
  }

  #about--press {
    margin: 20px 48px 0;
  }

  #about--menu {
    text-align: center;
    top: $header-height;
    background: #e6773e;
    left: 0;
    width: auto;
    right: 0;
    height: auto;
    margin: 0;
    padding: 0 20px 0 20px;
  }

  .talks {
    #about--menu {
      background: #040811;
    }
  }

  .press {
    #about--menu {
      background: #3e0e19;
    }
  }

  #about--menu li {
    display: inline-block;
    margin: 0 8px;
  }
}
