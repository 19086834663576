/**
 * @license
 * MyFonts Webfont Build ID 3853948, 2020-01-07T11:41:05-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: BRHendrix-Regular by Brink
 * URL: https://www.myfonts.com/fonts/brink/br-hendrix/regular/
 *
 * Webfont: BRHendrix-RegularItalic by Brink
 * URL: https://www.myfonts.com/fonts/brink/br-hendrix/regular-italic/
 *
 * Webfont: BRHendrix-Medium by Brink
 * URL: https://www.myfonts.com/fonts/brink/br-hendrix/medium/
 *
 * Webfont: BRHendrix-SemiBold by Brink
 * URL: https://www.myfonts.com/fonts/brink/br-hendrix/semi-bold/
 *
 * Webfont: BRHendrix-Bold by Brink
 * URL: https://www.myfonts.com/fonts/brink/br-hendrix/bold/
 *
 * Webfont: BRHendrix-Black by Brink
 * URL: https://www.myfonts.com/fonts/brink/br-hendrix/black/
 *
 *
 * Licensed pageviews: 10,000
 * Webfonts copyright: Copyright © 2019 by Christoph York. All rights reserved. Modification of this file requires prior written permission from Brink Type.
 *
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3ace7c");


@font-face {
  font-family: 'Hendrix';
  font-weight: 400;
  font-style: normal;
  src: url('../css/hendrix/brink_-_br_hendrix_regular-webfont.woff2') format('woff2'),url('../css/hendrix/brink_-_br_hendrix_regular-webfont.woff') format('woff'),url('../css/hendrix/brink_-_br_hendrix_regular-webfont.ttf') format('truetype');
}


@font-face {
  font-family: 'Hendrix';
  font-weight: 400;
  font-style: italic;
  src: url('../css/hendrix/brink_-_br_hendrix_regular_italic-webfont.woff2') format('woff2'),url('../css/hendrix/brink_-_br_hendrix_regular_italic-webfont.woff') format('woff'),url('../css/hendrix/brink_-_br_hendrix_regular_italic-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'Hendrix';
  font-weight: 500;
  font-style: normal;
  src: url('../css/hendrix/brink_-_br_hendrix_medium-webfont.woff2') format('woff2'),url('../css/hendrix/brink_-_br_hendrix_medium-webfont.woff') format('woff'),url('../css/hendrix/brink_-_br_hendrix_medium-webfont.ttf') format('truetype');
}


@font-face {
  font-family: 'Hendrix';
  font-weight: 600;
  font-style: normal;
  src: url('../css/hendrix/brink_-_br_hendrix_semibold-webfont.woff2') format('woff2'),url('../css/hendrix/brink_-_br_hendrix_semibold-webfont.woff') format('woff'),url('../css/hendrix/brink_-_br_hendrix_semibold-webfont.ttf') format('truetype');
}


@font-face {
  font-family: 'Hendrix';
  font-weight: 700;
  font-style: normal;
  src: url('../css/hendrix/brink_-_br_hendrix_bold-webfont.woff2') format('woff2'),url('../css/hendrix/brink_-_br_hendrix_bold-webfont.woff') format('woff'),url('../css/hendrix/brink_-_br_hendrix_bold-webfont.ttf') format('truetype');
}


@font-face {
  font-family: 'Hendrix';
  font-weight: 800;
  font-style: normal;
  src: url('../css/hendrix/brink_-_br_hendrix_black-webfont.woff2') format('woff2'),url('../css/hendrix/brink_-_br_hendrix_black-webfont.woff') format('woff'),url('../css/hendrix/brink_-_br_hendrix_black-webfont.ttf') format('truetype');
}

$font-features-global: "ss03" on, "ss01" off, "ss02" off; // access BR Hendrix’s stylistic set 03 for single-story ‘g’
$font-features-zero: "ss03" on, "ss01" off, "ss02" off, "zero" on; // single-story ‘g’ + slashed zero
$font-features-tabular: "ss03" on, "ss01" off, "ss02" off, "tnum" on; // single-story ‘g’ + slashed zero + tabular numerals

@mixin stylistic_alternates($style) {
  -moz-font-feature-settings: $style;
  -ms-font-feature-settings: $style;
  -webkit-font-feature-settings: $style;
  font-feature-settings: $style;
}

@mixin hendrix() {
  font-family: 'Hendrix', 'Helvetica Neue', Arial, sans-serif;
  @include stylistic_alternates($font-features-global);
}

// fathom icon font
@font-face {
  font-family: 'Fathom Icons';
  font-weight: 400;
  font-style: normal;
  // src: url('../css/icons/F2020.eot');src: url('../css/icons/F2020.eot?#iefix') format('embedded-opentype'),url('../css/icons/F2020.woff') format('woff'),url('../css/icons/F2020.ttf') format('truetype'),url('../css/icons/F2020.svg#icomoon') format('svg');
  // src: url('../css/icons/F2023.eot');src: url('../css/icons/F2023.eot?#iefix') format('embedded-opentype'),url('../css/icons/F2023.woff') format('woff'),url('../css/icons/F2023.ttf') format('truetype'),url('../css/icons/F2023.svg#icomoon') format('svg');
  src: url('../css/icons/F2024.woff2') format('woff2'),
    url('../css/icons/F2024.woff') format('woff'),
    url('../css/icons/F2024.ttf') format('truetype');
}
