$scrollbar-width: 10px;
$header-height: 60px;
$header-link-pad: calc($header-height / 2);
$header-hamburger-width: 22px;
$header-hamburger-height: 2px;
$header-x-line-length: 26px;
$gallery-spacing: 25px;
$section-gap: 96px;
$about-menu: 112px;
$about-overhead: $header-height + 84px;
$bio-paragraph-gap: 8px;
$project-gap: 44px;
$project-gap-inset: calc($project-gap / 2);
$project-gap-thin: $header-link-pad;
$project-text-left-gap: 36px;
$work-side: $header-link-pad;
$work-sides: $work-side * 2;
$archive: 1070px;
$archive-links: 240px;
$archive-client: 280px;
$archive-name: 300px;
$archive-gutter: 60px;
$archive-year: $archive - $archive-links - $archive-client - $archive-name - $archive-gutter * 3;
$archive-gap: 48px;
$archive-link-icons: 24px;
$archive-two-column: 660px;
$archive-two-column-gutter: 48px;
$archive-two-column-links: $archive-links;
$archive-two-column-project: $archive-two-column - $archive-two-column-links - $archive-two-column-gutter - $archive-link-icons;
$player-height: 72px;
$player-button: 24px;
$player-button-after: 24px;
$player-side: $player-button + $player-button-after;
$player-title-above: calc(($player-height - $player-button) / 2) - 2px;

// typography

@mixin airtight() {
  font-size: 0;
}

@mixin icon() {
  font-family: 'Fathom Icons';
  text-transform: none;
  font-weight: normal;
}

@mixin no_select(){
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin letter_spacing($sizeValue: 0) {
  letter-spacing: ($sizeValue * 0.001) + rem;
}

@mixin columns($columnCount) {
  -webkit-column-count: $columnCount;
     -moz-column-count: $columnCount;
          column-count: $columnCount;
}

@mixin columnGap($columnGap) {
  -webkit-column-gap: $columnGap;
     -moz-column-gap: $columnGap;
          column-gap: $columnGap;
}

@mixin breaker() {
  overflow-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// project grid

$w-wide: 1200px;
$w-target: 1000px;
$w-medium: 870px;
$w-compact: 700px;
$w-narrow: 440px;//400px;

$project-unit-wide: 16px;
$project-unit-target: 14px;
$project-unit-medium: 10px;

$project-image-max-scalar: 38; // Widest featured image: Laniakea
$project-image-max: $project-unit-wide * $project-image-max-scalar; // Default for widest breakpoint

@mixin scale_project_images($unit) {

  $project-1x: $unit * 8;
  $project-2x: $unit * 12;
  $project-3x: $unit * 16;
  $project-4x: $unit * 20;
  $project-5x: $unit * 24;

  $project-1x-wrap: $project-gap + $project-1x;
  $project-2x-wrap: $project-gap + $project-2x;
  $project-3x-wrap: $project-gap + $project-3x;
  $project-4x-wrap: $project-gap + $project-4x;
  $project-5x-wrap: $project-gap + $project-5x;

  .work--project--scale-5 {height: $project-5x-wrap}
  .work--project--scale-4 {height: $project-4x-wrap}
  .work--project--scale-3 {height: $project-3x-wrap}
  .work--project--scale-2 {height: $project-2x-wrap}
  .work--project--scale-1 {height: $project-1x-wrap}

  .work--project--scale-5 .work--project--image {height: $project-5x}
  .work--project--scale-4 .work--project--image {height: $project-4x}
  .work--project--scale-3 .work--project--image {height: $project-3x}
  .work--project--scale-2 .work--project--image {height: $project-2x}
  .work--project--scale-1 .work--project--image {height: $project-1x}

  $project-image-max: $unit * $project-image-max-scalar;
  .work--project--image {width: $project-image-max}

}

$project-target: $w-medium - $scrollbar-width - $work-sides;
$project-text-target: $project-target - $project-unit-target * $project-image-max-scalar;

$project-medium: $w-compact - $scrollbar-width - $work-sides;
$project-text-medium: $project-medium - $project-unit-medium * $project-image-max-scalar;

// colors
$dark-bg: #000008;
$project-red: #e63259;
$header-link-bg: rgba(200, 195, 196, 0.15);

@mixin selection_light() {
  ::-moz-selection { background-color: #eaecf5; }
  ::selection { background-color: #eaecf5; }
}

@mixin selection_dark() {
  ::-moz-selection { background-color: #aab4c5; }
  ::selection { background-color: #aab4c5; }
}

@mixin link_light() {
  @include smoothly(all, 0.25s);
  position: relative;
  color: #000008;
  text-decoration: none;
  padding: 4px 0 2px;
  background-color: transparent;
  border-bottom: 2px solid #c0c8db;
  &:hover {
    color: #000008;
    background-color: #dee4f0;
    border-bottom-color: #dee4f0;
  }
}

@mixin link_dark() {
  @include link_light;
  color: #fff;
  border-bottom-color: #434b5e;
  &:hover {
    color: #fff;
    background-color: #2b303c;
    border-bottom-color: #2b303c;
  }
}

@mixin link_red() {
  @include link_light;
  color: $project-red;
  border-bottom-color: $project-red;
  &:hover {
    color: #fff;
    background-color: $project-red;
    border-bottom-color: $project-red;
  }
}

@mixin shadow($h-offset, $v-offset, $blur-radius, $spread-radius, $rgba) {
  -moz-box-shadow:    $h-offset $v-offset $blur-radius $spread-radius $rgba;
  -webkit-box-shadow: $h-offset $v-offset $blur-radius $spread-radius $rgba;
  box-shadow:         $h-offset $v-offset $blur-radius $spread-radius $rgba;
}

@mixin gradient_2($deg, $color1, $stop1, $color2, $stop2) {
  background: -webkit-linear-gradient($deg, $color1 $stop1, $color2 $stop2);
  background: -moz-linear-gradient($deg, $color1 $stop1, $color2 $stop2);
  background: linear-gradient($deg, $color1 $stop1, $color2 $stop2);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

@mixin gradient_3($deg, $color1, $stop1, $color2, $stop2, $color3, $stop3) {
  background: -webkit-linear-gradient($deg, $color1 $stop1, $color2 $stop2, $color3 $stop3);
  background: -moz-linear-gradient($deg, $color1 $stop1, $color2 $stop2, $color3 $stop3);
  background: linear-gradient($deg, $color1 $stop1, $color2 $stop2, $color3 $stop3);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

@mixin gradient_4($deg, $color1, $stop1, $color2, $stop2, $color3, $stop3, $color4, $stop4) {
  background: -webkit-linear-gradient($deg, $color1 $stop1, $color2 $stop2, $color3 $stop3, $color4 $stop4);
  background: -moz-linear-gradient($deg, $color1 $stop1, $color2 $stop2, $color3 $stop3, $color4 $stop4);
  background: linear-gradient($deg, $color1 $stop1, $color2 $stop2, $color3 $stop3, $color4 $stop4);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

@mixin scrollbar() {
  body {
    scrollbar-width: $scrollbar-width;
    &::-webkit-scrollbar {
      width: $scrollbar-width;
    }
    &::-webkit-scrollbar-track {
      background: #10121c;
    }
    &::-webkit-scrollbar-thumb {
      width: $scrollbar-width;
      background: #464a57;
    }
  }
}

// Color functions
@function tint($color, $percentage) {
  @return mix(white, $color, 100%-$percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, 100%-$percentage);
}

// transitions
@mixin smoothly($attribute, $speed) {
  -moz-transition: $attribute $speed;
  -webkit-transition: $attribute $speed;
  transition: $attribute $speed;
}

// transitions
@mixin smoothlyDelay($attribute, $speed, $delay) {
  -moz-transition: $attribute $speed $delay;
  -webkit-transition: $attribute $speed $delay;
  transition: $attribute $speed $delay;
}
