@import 'fonts';
@import 'mixins';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

@include scrollbar;

body, html {
  @include airtight;
  @include selection_light;
  @include hendrix;
  font-weight: 400;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  &.dark {
    @include selection_dark;
    background: $dark-bg;
  }
}

.icon {
  @include icon;
}

p {
  margin: unset;
  padding: unset;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@import 'widgets';

@import 'headers';
@import 'home';
@import 'careers';
@import 'tools';
@import 'text';
@import 'contact';
@import 'work';
@import 'project';
@import 'theme';
@import 'footers';
@import 'about';
@import 'notebook';
@import 'error';
@import 'privacy';
