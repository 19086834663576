.project-page--wrapper {
  color: #000008;
  margin-top: $header-height;
  margin-bottom: $section-gap;
  a {
    @include link_light();
  }
}

.dark .project-page--wrapper {
  a {
    @include link_dark();
  }
}

.project-page--inner {
  // max-width: 1100px;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 90px;
  font-size: 20px;
  line-height: 32px;
}

.project-page--upper {
  margin-bottom: 90px;
  overflow: auto;
}

.project-page--upper::after {
  content: "";
  clear: both;
  display: table;
}

// .project-page--upper-left {
.project-page--title, .project-page--capabilities {
  width: 360px;
  float: left;
}

.project-page--client {
  color: $project-red;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  height: auto;
  margin: 0;
}

.project-page--client-inner {
  width: 360px;
  display: inherit;
  font-size: inherit;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-inline-start: inherit;
  margin-inline-end: inherit;
  font-weight: inherit;
}

.project-page--title {
  color: #000008;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  margin: 0;
  height: auto;
  padding: 16px 0;
}

.project-page--client,
.project-page--capabilities {
  padding-left: 2px; // optical alignment for project title
}

.project-page--capabilities {
  margin-top: 36px;
}

.project-page--highlights--header {
  margin: 0 0 12px;
  padding: 0;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: 700;
  color: #98a4b4;
}

.project-page--capabilities--list {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01rem;
    margin-bottom: 12px;
  }
}

.project-page--intro {
  width: 580px;
  float: right;
}

.project-page--intro {
  padding-top: 20px;
  font-size: 22px;
  line-height: 38px;
  letter-spacing: 0.005rem;
  p {
    margin: 0;
    padding: 0;
    & + p {
      margin-top: 16px;
    }
  }
}

.project-page--update {
  display: block;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}


.project-page--content--text {
  font-size: 20px;
  line-height: 32px;
  margin: 24px auto;
}

.project-page--content--text,
.f-img--caption {
  max-width: 550px;
}

.f-img--caption {
  margin-left: auto;
  margin-right: auto;
}

.project-page--content--text + .f-img,
.f-img + .project-page--content--text {
  margin-top: 48px;
}

.project-page--content--header {
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.01rem;
  margin: 48px auto 0;
  max-width: 550px;
}

h5.project-page--content--header {
  font-size: 20px;
  line-height: 28px;
  // font-weight: 600;
}

.f-img + .project-page--content--header {
  margin-top: 50px;
}

.project-page--content--numbered, .project-page--content--bulleted {
  max-width: 550px;
  margin: 20px auto 40px auto;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01rem;
  // padding: 0 0 0 60px;
  box-sizing: border-box;
  li {
    margin-bottom: 20px;
    padding-left: 12px;
  }
}

ul,ol {
  max-width: 550px;
  margin: 24px auto;

}

.project-page--content--bulleted {
  padding: 0 0 0 18px;
}

.project-page--content--numbered {
  @include stylistic_alternates($font-features-tabular);
  padding: 0 0 0 20px;
}

.project-page--highlights {
  max-width: 700px;
  margin: 40px auto 40px auto;
  overflow: auto;
}

.project-page--highlights::after {
  content: "";
  clear: both;
  display: table;
}

.project-page--highlights--left, .project-page--highlights--right {
  display: inline-block;
}

.project-page--highlights--left {
  float: left;
}

.project-page--highlights--right {
  float: right;
}

#project-page--achievements--header {
  position: relative;
  padding-left: 25px;
}

#project-page--achievements--header:before {
  content: '}';
  position: absolute;
  left: 0;
  top: -1px;
  font-family: 'Fathom Icons';
}

#project-page--challenges--header {
  position: relative;
  padding-left: 25px;
}

#project-page--challenges--header:before {
  content: '{';
  position: absolute;
  left: 0;
  top: -1px;
  font-family: 'Fathom Icons';
}

.project-page--highlights--list {
  width: 300px;
  padding: 0;
  margin: 0;
  color: #7f8b9b;
  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01rem;
    margin-bottom: 20px;
  }
}

.project-page--highlights-highlight {
  color: #374453;
}

.project-page--content--video {
  margin: 64px auto;
}

.project-page--twitter-mentions {
  margin-top: 50px;
  // twitter-widget {
  //   margin: 30px auto !important;
  // }

  

  .twitter-tweet-rendered {
    margin-left: auto;
    margin-right: auto;
  }
}

.twitter-tweet {
    margin: auto ;
  }

// dark colorscheme

.project-page--dark {
  &.project-page--wrapper {
    // background: $dark-bg;
    color: #fff;
  }
  .project-page--title {
    color: #fff;
  }

  .project-page--highlights--header {
    color: #6b7283;
  }

  .project-page--highlights--list {
    color: #98a4b4;
  }

  .project-page--highlights-highlight {
    color: #fff;
  }
}

@media(max-width: 1200px){
  .project-page--inner {
    max-width: 800px;
  }

  .project-page--client {
    font-size: 20px;
    line-height: 25px;
  }

  .project-page--client-inner {
    width: 320px;
  }

  // .project-page--upper-left {
  .project-page--title, .project-page--capabilities {
    width: 320px;
  }

  .project-page--title {
    line-height: 36px;
    margin-top: 5px;
  }

  .project-page--capabilities--list {
    li {
      font-size: 16px;
    }
  }

  // .project-page--upper-right {
  .project-page--intro {
    width: 400px;
    font-size: 20px;
    line-height: 34px;
    padding-top: 25px;
  }
}

@media(max-width: 900px){
  // .project-page--upper-left, .project-page--upper-right {
  .project-page--title, .project-page--capabilities, .project-page--intro {
    float: none;
  }

  .project-page--upper {
    margin: 0 40px 90px 40px;
  }

  .project-page--intro {
    width: auto;
  }

  .project-page--capabilities {
    width: auto;
  }

  .project-page--capabilities--list {
    -webkit-columns: 2;
    columns: 2;
  }

  .project-page--content {
    margin: 0 40px;
  }

  .project-page--capabilities--list {
    li {
      padding-right: 32px;
    }
  }
}


@media(max-width: 750px){
  .project-page--highlights--right, .project-page--highlights--left {
    display: block;
    float: none;
    margin: 0 auto 50px;
    width: 300px;
  }
}

@media(max-width: $w-narrow){
  .project-page--inner {
    padding-top: 50px;
  }

  .project-page--title, .project-page--capabilities {
    width: auto;
  }

  .project-page--capabilities--list {
    -webkit-columns: 1;
    columns: 1;
  }

  .project-page--content {
    margin: 0 20px;
  }

  .project-page--upper {
    margin: 0 20px 90px 20px;
  }

  .project-page--content--text, .project-page--intro {
    font-size: 18px;
    line-height: 30px;
  }

  .project-page--highlights--left, .project-page--highlights--right, .project-page--highlights--list {
    width: auto;
  }
}
