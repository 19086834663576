footer {
  position: relative;
  z-index: 100;
  padding-top: $section-gap;
  background: $dark-bg;
}

.project-page--dark,
.theme-page--wrapper {
  & + footer {
    border-top: 2px solid #10121c;
  }
}

#footer--big-links {
  max-width: 900px;
  margin: 0 auto $section-gap;
  text-align: center;
}

.footer--big-link:first-child, .big-link-1 {
  .footer--big-link--container {
    margin-right: 40px;
  }
}

.footer--big-link--container {
  @include smoothly(transform, 0.25s);
  display: inline-block;
  position: relative;
  width: 385px;
  text-align: left;
  color: #fff;
  transform: none;
}

.footer--big-link--image {
  float: left;
  width: 150px;
  height: 190px;
  background: #ddd;
}

.footer--big-link {
  // overflow: auto;
  &.hiring {
    .footer--big-link--image {
      background: url('../css/img/hiring.jpg') no-repeat;
      background-size: cover;
      background-position: 50%;
    }
  }
  &.projects {
    .footer--big-link--image {
      background: url('../css/img/projects.jpg') no-repeat;
      background-size: cover;
      background-position: 50%;
    }
  }
  &:hover {
    .footer--big-link--container {
      transform: scale(1.08);
    }
    .footer--big-link--title {
      color: #91a4c1;
    }
    &.hiring {
      .footer--big-link--title {
        color: #b02d3e;
      }
    }
    &.projects {
      .footer--big-link--title {
        color: #5295cd;
      }
    }
  }
}

// .footer--big-link::after {
//   content: "";
//   clear: both;
//   display: table;
// }

.footer--big-link--title, .footer--big-link--desc {
  float: right;
  width: 200px;
}

.footer--big-link--text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.footer--big-link--title {
  @include smoothly(color, 0.25s);
  color: #515c6b;
  margin-bottom: 12px;
  span {
    vertical-align: top;
    height: 32px;
    line-height: 32px;
  }
  .footer--big-link--title--text {
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .icon {
    font-size: 24px;
    margin-right: 10px;
    vertical-align: top;
    line-height: 30px;
    padding-bottom: 2px;
  }
}

.footer--big-link--desc {
  font-size: 16px;
  line-height: 24px;
}

#client-grid {
  @include airtight;
  @include no_select;
  max-width: 1200px;
  margin: 0 auto $section-gap;
  text-align: center;
  a {
    text-decoration: none;
  }
}

.client-box--link {
  color: #fff;
  display: inline-block;
  width: auto;
  height: auto;
}

.client-box {
  @include smoothly(background-color, 0.25s);
  background-color: #10121c;
  display: inline-block;
  position: relative;
  width: 220px;
  height: 100px;
  overflow: hidden;
  padding: 0;
  margin: 3px;
  pointer-events: none;

  &.has-work {
    pointer-events: all;
    &:hover {
      background-color: #202530;
      .client-box--logo-static,
      .client-box--logo-hover {
        width: 85%;
      }
      .client-box--logo-hover {
        opacity: 1;
      }
      .client-box--hover {
        margin-top: -20px;
        opacity: 1;
      }
    }
  }
}

.client-box--hover {
  @include smoothly(all, 0.25s);
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  top: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  color: #91a4c1;
}

.client-box--logo {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.client-box--logo-static, .client-box--logo-hover {
  @include smoothly(all, 0.25s);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: inherit;
  transform: translateX(-50%);
}

.client-box--logo-hover {
  // @include smoothly(opacity, 0.25s);
  opacity: 0;
}

#footer-statement {
  max-width: 550px;
  margin: 0 auto $section-gap;
  p {
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 20px 0;
  }
}

#footer-statement--help {
  a {
    @include link_dark;
  }
}

#footer-footer {
  @include no_select;
  background: url('../css/img/footer.jpg') no-repeat;
  background-size: cover;
  width: 100%;
  height: 90px;
  text-align: center;
  position: relative;
}

#footer-footer--inner {
  // line-height: 50px;
  color: rgba(152, 164, 180, 0.6);
  margin: 0 auto;
  position: absolute;
  // left: 50%;
  top: 35px;
  height: 15px;
  // transform: translateX(-50%);
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
    @include smoothly(color, 0.15s);
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
}

// #footer-footer--inner {
//     width: 100%;
//     left: 0;
//     transform: none;
  // }

.footer-footer--section {
  display: inline-block;
  border-right: 1px solid rgba(64, 65, 72, 0.6);
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 14px;
  padding: 0 20px;
  &:last-child {
    border: none;
  }
  .copyright {
    font-weight: 400;
  }
}

#footer-footer--social {
  @include airtight;
  text-transform: none;
  font-family: 'Fathom Icons';
  a {
    font-size: 14px;
    padding: 0 8px;
  }
}

#footer-footer--instagram {
  font-size: 12px;
}

@media(max-width: 1200px){
  #client-grid {
    max-width: 1000px;
  }
}

@media(max-width: 1000px){
  #client-grid {
    max-width: 460px;
  }
}

@media(max-width: 900px){
  #footer--big-links {
    max-width: 500px;
  }

  .footer--big-link {
    margin-bottom: 35px;
    display: block;
  }

  .footer--big-link:first-child .footer--big-link--container {
    margin-right: 0;
  }


  // #footer-footer--inner {
  //   width: 100%;
  //   left: 0;
  //   transform: none;
  // }
}

@media(max-width: 699px){
  .footer--big-link--container {
    width: auto;
    float: none;
  }

  .footer--big-link {
    margin-bottom: 48px;
  }

  .footer--big-link--text {
    display: inline-block;
    position: relative;
    margin-left: 24px;
    padding-top: 12px;
    top: unset;
    transform: none;
  }

  .footer--big-link--image {
    width: 138px;
    height: 186px;
  }

  .footer--big-link--title, .footer--big-link--desc {
    float: none;
    width: 180px;
  }

  #client-grid {
    // max-width: 225px;
    max-width: 300px;
  }

  .client-box {
    width: 146px;
    height: 66px;
    overflow: hidden;
    padding: 0;
    margin: 2px;
    .client-box--hover {
      height: 18px;
      line-height: 18px;
    }
    &.has-work {
      &:hover {
        .client-box--hover {
          margin-top: -18px;
        }
      }
    }
  }

  #footer-statement {
    max-width: 300px;
    p {
      font-size: 16px;
      line-height: 26px;
    }
  }

  #footer-footer {
    height: 50px;
  }

  #footer-footer--inner {
    top: 16px;
  }

  .footer-footer--section {
    font-size: 10px;
    padding: 0 6px;
  }

  #footer-footer--instagram {
    font-size: 9px;
  }
}

@media(max-width: 440px){
  .footer-footer--section:first-child {
    width: 70px;
    overflow: hidden;
  }
}


@media(max-width: $w-narrow){
  .footer--big-link--image {
    margin: 0 auto;
    float: none;
  }

  .footer--big-link--text {
    display: block;
    margin: 0 auto;
    float: none;
    //width: 200px;//138px;
    padding: 20px 35px 0 60px;
  }

  .footer--big-link--title .icon {
    display: none;
  }

  .footer--big-link--title .footer--big-link--title--text {
    line-height: 22px;
    height: auto;
  }

  .footer--big-link--title, .footer--big-link--desc {
    width: 100%;
  }
}
