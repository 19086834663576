header {
  @include no_select;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  z-index: 900;
}

#header-bg {
  @include smoothly(all, 0.3s);
  background: url('../css/img/header.jpg') no-repeat #c54504;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

#header--home-link {
  @include smoothly(color, 0.25s);
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  padding: 0 $header-link-pad;
  height: $header-height;
  line-height: $header-height;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Fathom Icons';
  color: #ffb9ac;
  text-decoration: none;
  opacity: 1;
  &:hover {
    color: #ffffff;
  }
}

#header--links--hamburger {
  display: none;
}

$header-divider-offset: 15px;
#header--links {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: $header-height;
  padding: 0;
  margin: 0;
  a {
    color: #fff;
    text-decoration: none;
    &:first-child {
      .header--link-divider {
        display: none;
      }
    }
    &:hover, &.active {
      li {
        background-color: $header-link-bg;
        .header--link-divider {
          display: none;
        }
      }
      & + a {
        li {
          .header--link-divider {
            display: none;
          }
        }
      }
    }
  }
  // width: 400px;
  li {
    @include smoothly(background-color, 0.2s);
    background-color: transparent;
    // width: 150px;
    // width: 100px;
    position: relative;
    height: $header-height;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: $header-height;
    padding: 0 25px;
    text-align: center;
    margin: 0;
  }

  .header--link-divider {
    position: absolute;
    left: 0;
    top: $header-divider-offset;
    bottom: $header-divider-offset;
    width: 1px;
    background-color: $header-link-bg;
  }
}

@media(max-width: 900px){
  #header--links {
    width: 500px;
    text-align: center;
    height: $header-height;
    // padding-bottom: 5px;
    li {
      font-size: 14px;
      line-height: $header-height;
      padding-bottom: 7px;
    }
  }
}

@media(max-width: 767px){

  #header--home-link {
    color: #ffffff;
  }

  #header--links {
    display: none;
    pointer-events: none;
    position: fixed;
    padding-top: $header-height;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100%;
    background-color: $dark-bg;
    li {
      display: block;
      height: 96px;
      line-height: 96px;
      font-size: 20px;
      .header--link-divider {
        display: none;
      }
    }
    &.expanded {
      display: block;
      pointer-events: all;
      &:before {
        display: block;
      }
    }
  }

  #header--links--hamburger {
    display: block;
    width: $header-height;
    height: $header-height;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;
    &.expanded, &.expanded:hover {
      .header--links--hamburger--line {
        width: $header-x-line-length;
      }
      #header--links--hamburger--line-2 {
        opacity: 0;
      }
      #header--links--hamburger--line-1 {
        transform: translateX(50%) rotate(-135deg);
        margin: 0;
      }
      #header--links--hamburger--line-3 {
        transform: translateX(50%) rotate(135deg);
        margin: 0;
      }
    }
  }

  .header--links--hamburger--line {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateX(50%);
    width: $header-hamburger-width;
    height: $header-hamburger-height;
    background: #ffffff;
    opacity: 1;
    transform-origin: center;
    @include smoothly(all, 0.3s);
  }
  #header--links--hamburger--line-1 {
    margin-top: -10px - $header-hamburger-height;
  }
  #header--links--hamburger--line-2 {
    margin-top: -1 * $header-hamburger-height;
  }
  #header--links--hamburger--line-3 {
    margin-top: 10px - $header-hamburger-height;
  }
}
