#careers {
  background-color: #ffffff;
}

#careers--wrapper {
  font-size: 20px;
  line-height: 34px;
  padding-top: $header-height;

  width: 900px;
  margin: 0 auto;

  &.job-listings--single {
    width: 625px;
  }

  a {
    @include link_light();
  }
  p {
    margin-bottom: 20px;
  }
}

#careers--banner {
  padding-top: $header-height;
  width: 100%;
  height: auto;
}

#careers--intro {
  width: 550px;
  margin: 0 auto;
  font-size: 20px;
  h2 {
    color: #8288a3;
    font-weight: 700;
    font-size: 32px;
    margin: 48px 0;
  }
}

#job-listings {
  margin-top: 100px;
  @include columns(2);
  @include columnGap(80px);
  &.job-listings--single {
    @include columns(1);
  }
}

.job-listing {
  margin-bottom: 64px;
  -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
                 break-inside: avoid;
}

.job-listing--title {
  font-weight: 700;
  font-size: 21px;
  margin-bottom: 12px;
  color: $project-red;
}

.job-listing--desc {
  font-size: 18px;
  line-height: 30px;
  p {
    margin-bottom: 0;
    & + p {
      margin-top: 12px;
    }
  }
}

#contact-box {
  width: 400px;
  margin: 60px auto 0 auto;
  padding-bottom: 120px;

  a {
    @include link_red;
  }
}

@media(max-width: 1100px){
  #careers--wrapper, #careers--intro {
    // margin: 0 100px;
    margin: 0 auto;
    width: auto;
    max-width: 550px;
  }

  #job-listings {
    columns: 1;
  }

  #contact-box {
    margin-top: 0;
  }
}

@media (max-width: 700px){
  #careers--wrapper {
    // margin: 0 100px;
    margin: 0 40px;
    width: auto;
  }

  #contact-box {
    width: auto;
  }
}

@media(max-width: $w-narrow){
  #careers--wrapper {
    margin: 0 20px;
    padding-top: 30px;
  }

  #careers--intro {
    font-size: 18px;
    line-height: 32px;
    h2 {
      margin: 10px auto 30px;
    }
  }
}
