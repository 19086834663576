#error-page--wrapper {
  position: relative;
  background: $dark-bg;
  margin-top: $header-height;
  height: 280px;
  background-image: url('../css/img/pattern/link-tile.svg');
  background-size: 256px 240px;
  background-repeat: repeat;
  background-position: top left;
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
  }
  .br--404--compact {
    display: none;
  }
}

@media(max-width: 500px){
  #error-page--wrapper {
    background-size: 192px 180px;
    p {
      white-space: normal;
      font-size: 18px;
    }
    .br--404--compact {
      display: inline;
    }
  }
}
