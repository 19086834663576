#tools--wrapper {
  font-size: 20px;
  line-height: 34px;
  padding-top: $header-height;

  width: 900px;
  margin: 0 auto;

  color: #fff;

  h2 {
    // margin-top: 65px;
    // margin-bottom: 40px;
    margin: 100px 0 40px 0;
  }

  a {
    @include link_light();
  }
  p {
    margin-bottom: 20px;
  }

  .f-img {
    margin-bottom: 30px;
  }

  .f-img--caption {
    color: #aab4c5;
  }
}

@media(max-width: 1100px){
  #tools--wrapper {
    // margin: 0 100px;
    margin: 0 auto;
    width: auto;
    max-width: 550px;
  }
}

@media (max-width: 700px){
  #tools--wrapper {
    // margin: 0 100px;
    margin: 0 40px;
    width: auto;
  }
}

@media(max-width: $w-narrow){
  #tools--wrapper {
    margin: 0 20px;
    padding-top: 30px;
  }
}