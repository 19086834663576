#text--wrapper {
  font-size: 20px;
  line-height: 34px;
  padding-top: $header-height;

  width: 900px;
  margin: 0 auto;

  color: #fff;
  h2 {
    margin: 100px 0 40px 0;
  }

  a {
    @include link_light();
    color: #fff;
  }
  p {
    margin-bottom: 20px;
  }

  .f-img {
    margin-bottom: 30px;
  }

  .f-img--caption {
    color: #aab4c5;
  }

  #mc_embed_signup {

    div {
      display: inline-block;
    }

    input {
      @include hendrix;
      background: #000;
      border: 1px solid #fff;
      font-size: 20px;
      line-height: 32px;
      padding: 3px 6px;
      color: #fff;
    }
  }
}

@media(max-width: 1100px){
  #text--wrapper {
    // margin: 0 100px;
    margin: 0 auto;
    width: auto;
    max-width: 550px;
  }
}

@media (max-width: 700px){
  #text--wrapper {
    // margin: 0 100px;
    margin: 0 40px;
    width: auto;
  }
}

@media(max-width: $w-narrow){
  #text--wrapper {
    margin: 0 20px;
    padding-top: 30px;
  }
}
