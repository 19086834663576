// f-img

.f-img {
  max-width: 550px;
  margin: 0 auto;

  &.f-img--size-s {
    max-width: 300px;
  }

  &.f-img--size-l {
    max-width: 800px;
  }

  &.f-img--size-xl {
    max-width: 1200px;
  }

  &.f-img--size-full {
    width: 100%;
    max-width: none;
  }

  img {
    width: 100%;
  }

  a {
    text-decoration: none;
  }
}

.f-img--caption {
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
}

.project-page--wrapper .f-img a, .dark .project-page--wrapper .f-img a {
  border: none;
  &:hover {
    padding: 0;
    background: none;
  }
}

@media(max-width: 1200px){
  .f-img {
    &.f-img--size-xl {
      max-width: 800px;
    }
  }
}



// gallery
.gallery {
  // max-width: 800px;
  text-align: center;
  margin: 60px auto;
}

.gallery--img {
  width: 40%;
  margin: 1%;
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
  // background-color: #10121c;
}

.gallery--columns-4 {
  .gallery--img {
    width: 22%;
  }
}

.gallery--columns-3 {
  .gallery--img {
    width: 30%;
  }
}

.gallery--columns-2 {
  .gallery--img {
    width: 40%;
  }
}

@media(max-width: 900px){
  .gallery--img, .gallery--columns-2 .gallery--img, .gallery--columns-3 .gallery--img,  .gallery--columns-4 .gallery--img {
    width: 100%;
    margin: 3% auto;
    display: block;
  }
}

// from old wordpress
// .modal {
//   .modal-controls {
//     .button {
//       &.close {
//         right: 0;
//       }
//     }
//   }
//   .modal-inner {
//     #swipe-detector {
//       display: block;
//     }
//     .button {
//       .mobile-button {
//         display: block;
//       }
//     }
//   }
// }

.modal {
  background: rgba(255,255,255,0.95);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  // .text-select-off();

  .loader {
    // animation: spinning 0.7s infinite linear;
    // background: url('images/spinning-arrow.svg') no-repeat;
    background: gainsboro;
    width: 50px;
    height: 50px;
    background-size: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -25px;
  }
}

.modal-inner {
  position: relative;
  // position: absolute;
  // width: 100%;
  // top: 50%;
  // height: auto;
  // transform: translateY(-50%);
  display: block;
  margin: 100px 1%;
  // margin-top: 50%;
  // transform: translateY(-50%);
  text-align: center;
  // height: 100%;

  #swipe-detector {
    position: absolute;
    left: 20px;
    top: 0;
    right: 20px;
    bottom: 0;
    display: none;
  }

  .button {
    cursor: pointer;
    font-family: 'Fathom Icons';
    color: #000;
    font-size: 30px;
    position: absolute;
    // top: 50%;
    // margin-top: -50px;
    top: 0;
    // bottom: 0;
    width:33%;
    height: 100%;

    .mobile-button {
      display: none;
      width: 20px;
      height: 20px;
      background: #000;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      font-size: 10px;
      line-height: 20px;
      color: #fff;
    }

    &.prev {
      // left: -50px;
      left: 0;
      cursor: url('../css/img/cursor/previous.png'), w-resize;
      .mobile-button {
        left: 0;
      }
    }

    &.next {
      // right: -50px;
      right: 0;
      cursor: url('../css/img/cursor/next.png'), e-resize;
      .mobile-button {
        right: 0;
      }
    }
  }
  .caption-wrapper {
    @include no_select();
    position: fixed;
    left: -15px;
    bottom: 0;
    width: calc(100% + 30px);
    height: 100px;
    overflow-y: scroll;
    border-top: 1px solid #c0c8db;
    background-color: rgba(255, 255, 255, 0.8);
    &::after {
      content: '';
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 32px;
      background: linear-gradient(transparent, #ffffff);
      pointer-events: none;
    }
  }
  .caption {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 700px;
    margin: 24px auto;
    padding: 0 16px;
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    background-color: transparent;
    color: $dark-bg;
  }
}

.dark {
  .modal-inner {
    .button {
      &.prev {
        cursor: url('../css/img/cursor/previous-dark.png'), w-resize;
      }
      &.next {
        cursor: url('../css/img/cursor/next-dark.png'), e-resize;
      }
    }
  }
}

.modal-image {
  @include shadow(0, 4px, 12px, 0, rgba(0, 0, 8, 0.15));
  background-color: #ffffff;
  width: auto;
  height: auto;
  min-width: 300px;
  position: relative;
  display: none;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.modal-controls {
  // height: @header-collapsed;
  // height: 25px;
  height: 10px;
  position: relative;
  // max-width: @max-width;
  // width: @content-width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-top: @header-collapsed/2;

  .button {
    font-family: 'Fathom Icons';
    width: $gallery-spacing;
    height: $gallery-spacing;
    position: absolute;
    display: inline-block;
    // .logo-text();
    // font-size: 1.8em;
    font-size: 20px;
    // line-height: 3em;
    vertical-align: middle;
    // color: white;
    color: #98a4b4;
    cursor: pointer;
    // .smoothly();
    @include smoothly(all, 0.3s);
    z-index: 9999;

    &:hover {
      color: $dark-bg;
    }

    &.close {
      top: 0;
      right: 0;
      width: $header-height;
      height: $header-height;
      line-height: $header-height;
      text-align: center;
      vertical-align: none;
    }



    &.next {
      left: $gallery-spacing*1.5;
    }
    &.prev {
      left: 0px;
    }
  }
}

.dark {
  .modal {
    background: rgba(0,0,8,0.95);
  }
  .modal-inner {
    .caption-wrapper {
      border-top-color: #434b5e;
      background-color: rgba(0, 0, 8, 0.8);
      &::after {
        background: linear-gradient(transparent, $dark-bg);
      }
    }
    .caption {
      background-color: transparent;
      color: #ffffff;
    }
  }
  .modal-controls {
    .button {
      color: #434b5e;
      &:hover {
        color: #ffffff;
      }
    }
  }
}

// videos
.video-embed {
  margin: 0 auto;

  video {
    width: 100%;
  }
}


// interactive embeds
.interactive {
  text-align: center;
  margin: 0 auto;
}

.interactive iframe {
  border: none;
}

.interactive--error {
  display: none;
  img {
    width: 100%;
  }
}


@media(max-width: $w-narrow){
  .modal-inner .caption {
    padding: 8px;
  }
}
