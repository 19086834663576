#privacy--wrapper {
  font-size: 18px;
  line-height: 24px;
  color: $dark-bg;
  background: #ffffff;
  padding-top: $header-height;
}

#privacy--wrapper--inner {
  max-width: 890px;
  margin: 0 auto;
  padding: 80px 20px;

  h1 {
    line-height: 38px;
  }

  p {
    margin-top: 20px;
  }

  table {
    margin: 20px 0;
  }

  td {
    padding: 10px;
    border: 1px solid #000;
  }
}

.privacy--cookie-table--lifespan {
  width: 150px;
}

@media (max-width: 600px){
  #privacy--wrapper--inner {
    td {
      font-size: 12px;
      line-height: 14px;
      padding: 8px;
    }
  }
}

// .privacy--contact-us {
//   margin-top: 20px;
// }
