#contact--wrapper {
  font-size: 18px;
  line-height: 24px;
  color: $dark-bg;
  background: #ffffff;
  padding-top: $header-height;
}

#contact--wrapper--inner {
  width: 890px;
  margin: 0 auto;
  padding-top: 80px;
  text-align: right;
}

#contact--logo {
  color: #8288a3;
  -webkit-font-smoothing: antialiased;
  font-family: 'Fathom Icons';
  margin-right: 50px;
  margin-bottom: -6px;
  font-size: 20px;
}

#contact--map {
  width: 550px;
  height: 330px;
  background-image: url('../css/img/map.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#contact--logo, #contact--map {
  display: inline-block;
  vertical-align: bottom;
}

#contact--upper {
  @include no_select();
}

#contact--info {
  @include stylistic_alternates($font-features-zero);
  width: 550px;
  margin: 56px 0 0 auto;
  text-align: left;
  padding-bottom: 88px;
  font-size: 18px;
  line-height: 28px;
  div + div {
    margin-top: 20px;
    &#contact--info--social {
      margin-top: 48px;
    }
  }
}

#contact--info--phone {}

#contact--info--email {
  position: relative;
  a {
    @include link_red;
  }
}

#contact--info--social {
  display: inline-block;
  position: relative;
  color: #a6b1c8;
  height: 32px;
  line-height: 32px;
  a {
    display: block;
    @include smoothly(color, 0.25s);
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    &:before {
      @include smoothly(color, 0.25s);
      content: 't';
      position: relative;
      font-family: 'Fathom Icons';
      font-size: 24px;
      color: #b3bed3;
      margin-top: 2px;
      margin-right: 8px;
      vertical-align: top;
    }
  }
  &:hover {
    a {
      color: #8288a3;
      &:before {
        color: #8288a3;
      }
    }
  }
}

@media(max-width: 1024px){
  #contact--wrapper--inner {
    text-align: left;
    // width: auto;
    // margin: 0 100px;
    width: 550px;
    margin: 0 auto;
  }
  #contact--logo, #contact--map {
    display: block;
    margin: 0;
  }
  #contact--logo {
    margin-bottom: 40px;
  }
  #contact--info {
    width: 100%;
  }
}

@media (max-width: 700px){
  #contact--wrapper--inner {
    width: auto;
    margin: 0 40px;
  }

  #contact--map {
    width: 100%;
  }
}
