$notebook_gray: #99b1c1;

#notebook-wrapper {
  background: #fff;
  padding-top: $header-height;

  width: 900px;
  margin: 0 auto;
}

#notebook-list--intro {
  margin-top: 120px !important;
  margin-bottom: 40px !important;
  .notebook-list--intro--innercontainer{
    max-width: 650px;
    margin: auto;
    color: #8288a3;
    h1 {
      font-weight: 700;
      font-size: 32px;
    }
    .notebook-list--intro--em {font-weight: 600;}
  }
  a {
    @include link_light();
  }
}

.notebook-list--post {
  // font-size: 20px;
  // margin: 0 auto 40px auto;
  a {
    @include link_light();
  }

  // .notebook-post--intro {
  //   margin-top: 20px;
  // }
  margin-bottom: 60px;
}

// .notebook-post--buffer {
//   height: $header-height;
//   width: 100%;
// }

.notebook-post--anchor {
  scroll-margin-top: $header-height;
}

.notebook-post--wrapper {
  padding-top: $header-height;
  // padding-top: $header-height*3;

  a {
    @include link_light();
  }
  p {
    margin-bottom: 20px;
  }
}

#notebook-list--intro,
.notebook-post--innercontainer, 
.notebook--pagination {
  width: 900px;
  margin: 30px auto 100px auto;
  font-size: 18px;
  line-height: 30px;

  h2, h3, h4 {
    max-width: 650px;
    margin: 60px auto 0;
    line-height: 1.35em;
  }

  p {
    margin: 20px auto;
    // margin: 20px 0 20px auto;
    max-width: 650px;
    &.video-embed {
      max-width: unset;
      margin: 40px auto;
    }
  }

  blockquote {
    margin: 20px auto;
    // margin: 20px 0 20px auto;
    max-width: 650px;
    font-style: italic;
    border-left: 5px solid #eee;
    padding: 0 0 0 30px;

    p {
      margin-top: 0;
      margin-bottom: 10px;
     //& + p {margin-top: 0;}
    }
  }
  .blockquote--attrib {
    padding-left: 38px;
     //text-align: right;
  }

  ul,ol {
    max-width: 650px;
    margin: 0 auto;
    li::marker {
      color: #99b1c1;
    }
  }

  .section-divider {
    height: 1px;
    width: 100%;
    max-width: 650px;
    margin: 40px auto;
    background-color: #eee;
  }

  li {
    margin-bottom: 10px;
  }

  .f-img {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .gallery {
    margin: 0 auto;
  }

  .f-img--caption, .video-embed--caption {
    color: #696969;
    margin-top: 8px;
    font-size: 16px;
    line-height: 22px;
  }
  .video-embed--caption {
    display: block;
    //margin-left: 50%;
    margin: auto;
    max-width: 550px;
  }
  .li-marker {font-weight: 700;}

  .video-description {
    font-size: 14px;
    line-height: 1.43;
    padding: 1em;
    max-width: 550px;
    margin-block: 1em;
    margin-inline: auto;
    color: #696969;

    background-color: #eee;

    p {
      margin-block: 1.1em;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  hr {
    border: none;
    border-top: 1px solid $notebook_gray;
    margin: 50px 0;
  }

  .cp_embed_wrapper {
    margin: 40px auto;
  }
}

.notebook-post--featuredimage-container {
  width: 100%;
  max-width: 1200px;
  height: 400px;
  overflow:hidden;
  position: relative;
  margin: 0 auto;
}

.notebook-post--featuredimage {
  position: absolute;
  // top: -100px;
  // width: 1200px;
  width: 100%;
  // height: 550px;
  height: 100%;
  background: #ddd;
  overflow:hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.post--220802 {
  .notebook-post--featuredimage {
    top: -20px;
  }
  .notebook-post--featuredimage-mobile {
    top: -50px;
  }
}
.post--200310 {
  .notebook-post--featuredimage {top: -140px;}
  //.notebook-post--featuredimage-mobile {top: -110px;}
}
.post--201217 {
  .notebook-post--featuredimage {
    //top: 0;
    img {
      width: calc(100% + 200px);
      margin-left: -100px;
    }
  }
  .notebook-post--featuredimage-mobile {
    top: -20px;
    img{
      width: calc(100% + 40px);
      margin-left: -40px;
    }
  }
}.post--200106 {
  .notebook-post--featuredimage, .notebook-post--featuredimage-mobile {
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #eee;
    background: #fff;
    img {
      height: inherit;
      width: auto;
      margin: auto;
      display: block;
    }
  }
}
// .post--6775 {
//   .notebook-post--featuredimage {top: -140px;}
//   //.notebook-post--featuredimage-mobile {top: -110px;}
// }

.notebook-post--featuredimage-mobile {
  display: none;
  position: absolute;
  left: 0;
  // top: 0;
  // top: -100px;
  width: 100%;
  // height: 600px;
  height: 100%;
  background: #ddd;
  overflow:hidden;
  img {
    // width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.notebook-post--title-container {
  // margin-left: 150px;
  // margin: 0 0 0 auto;
  max-width: 650px;
  margin: 0 auto;
}

.notebook-post--title {
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
  color: #3b3b3b;
  @include smoothly(color, 0.3s);
  &:hover {color: #000000;}
}

.notebook-post--readmore {
  max-width: 650px;
  margin: 0 auto;
}

// .notebook-post--author-container { 
//   position: relative;
//   margin-left: 150px;
//   line-height: 20px;
//   font-size: 14px;
// }

// .notebook-post--author-container--inner {
//   width: 150px;
//   text-align: center;
//   position: absolute;
//   left: -200px;
//   top: 45px;
// }

.notebook-post--author-container { 
  // position: relative;
  // margin-left: 150px;
  line-height: 20px;
  font-size: 14px;
  max-width: 650px;
  margin: 0 auto 50px auto;
}

.notebook-post--author-container--inner {
  // width: 150px;
  // text-align: center;
  // position: absolute;
  // left: -200px;
  // top: 45px;
  display: flex;
  flex-direction: row-reverse;
}

.notebook-post--author--text {
  text-align: center;
  // display: flex;
  text-align: right;
  margin-right: 8px;
}

.notebook-post--date {
  color: $notebook_gray;
  font-weight: 600;
  
}

.notebook-post--author-image {
  width: 36px;
  height: 36px;
  border-radius: 20px;
  //margin: 2px 10px 0 0;
}

.notebook-post--author-name {
  a {
    color: $notebook_gray;
    border: none;
    font-weight: 600;
    &:hover {
      background-color: #eeeeee;
    }
  }
}

.notebook-post--intro {
  font-size: 20px;
  margin: 30px auto;
  max-width: 650px;
}

// .notebook--pagination {
//   font-size: 18px;
// }

.notebook--pagination {
  max-width: 650px;
  margin: 0 auto 30px auto;
  display: flex;
  justify-content: space-evenly;

  a {
    @include link_light();
  }
}

.notebook--pagination--buttonm {
  // display: 
}

.notebook-post--outro {
  margin-top: 85px;
  p {
    border-top: 1px #ddd solid;
    padding-top: 30px;
    color: #4a4a4a;
    span {font-weight: 600;}
  }
  margin-bottom: 65px;
}

@media (max-width: 950px){
  #notebook-list--intro,
  .notebook-post--innercontainer, 
  .notebook--pagination {
    width: 650px;
  }
  .notebook-post--intro {
    margin: 20px auto;
  }
}


@media (max-width: 768px){
  .notebook-post--featuredimage-container {
    // width: 768px;
    height: 256px;
  }
  .notebook-post--featuredimage {
    width: 100%;
  }
}


@media (max-width: 700px){
  #notebook-list--intro,
  .notebook-post--innercontainer, 
  .notebook--pagination {
    width: auto;
    margin: 20px 30px;//20px 20px 0 20px;
  }
}


@media (max-width: 740px){
  .notebook-post--featuredimage {
    // height: 300px;
    display: none;
  }

  .notebook-post--featuredimage-container {
    // width: 320px;
    width: 100%;
    // height: 450px;
    height: 350px;
    // height: 450px;
  }

  .notebook-post--featuredimage-mobile {
    display: block;
  }
}

  

@media (max-width: 530px){
  .notebook-post--featuredimage-container {
    // width: 320px;
    width: 100%;
    height: 200px;
  }

  // .notebook-post--featuredimage-mobile {
  //   height: 450px;
  // }
  .notebook-post--wrapper p {
      font-size: 16px;
      line-height: 28px;
      margin: 10px auto;
  }
}

@media(max-width: 320px){
  .notebook-post--featuredimage-container {
    height: 200px;
  }
}