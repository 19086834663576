.theme-page--wrapper {
  background: $dark-bg;
  color: #ffffff;
  font-size: 24px;
  line-height: 38px;
  margin-top: $header-height;
  padding-bottom: $section-gap;

  a {
    @include link_dark();
  }
}

.theme-page--banner {
  width: 100%;
  height: 300px;
  background: rgba(255,255,255,0.2);
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    position: absolute;
    // top: 0;
    // transform: translateY(-50%);
  }
}

.theme-page--inner {
  // max-width: 1100px;
  padding-top: 80px;

  h2 {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 48px;
  }

  p {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 24px;
  }

  p, h2, h3, .f-img--caption {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .f-img {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .f-img--caption {
    color: #a8b5ca;
  }

  #text--signup-form {
    max-width: 700px;
    margin-top: $section-gap;
    margin-left: auto;
    margin-right: auto;
    input, input:focus, input:active {
      border: none;
      outline: none;
    }
    input {
      @include hendrix;
      margin-bottom: 24px;
    }
    .mc-field-group input {
      background-color: #181c25;
      color: #ffffff;
      padding: 0 20px;
      font-size: 24px;
      font-weight: 400;
      line-height: 72px;
      height: 72px;
      &::placeholder {
        color: #515c6b;
      }
    }
    .mc-subscribe-button input {
      @include smoothly(background-position, 0.5s);
      background-color: #da4f16;
      background-image: url('../css/img/header.jpg');
      background-size: cover;
      background-position: 80% center;
      background-repeat: no-repeat;
      color: #ffffff;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 700;
      line-height: 56px;
      height: 56px;
      width: auto;
      cursor: pointer;
      &:hover {
        background-position: 100% center;
      }
    }
  }

}

@media(max-width: 800px){
  .theme-page--inner {
    margin: 0 40px;
    p {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
}

@media(max-width: $w-narrow){
  .theme-page--inner {
    padding-top: 40px;
    margin: 0 20px;
  }

  .theme-page--inner h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .theme-page--inner h3 {
    font-size: 22px;
    line-height: 34px;
  }

  .theme-page--inner p {
    font-size: 18px;
    line-height: 28px;
  }
}
